.ui-service-card__col {
    margin-top: 30px;
    margin-bottom: 30px;
}

.ui-service-card__container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-size: 100%;
    height: 100%;
    min-height: 450px;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.ui-service-card__bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
}

.ui-service-card__icon-container {
    text-align: right;
    margin-bottom: 20px;
}

.ui-service-card__content-container h3 {
    font-size: 18px;
    font-weight: 500;
}

.ui-service-card__points-list {
    font-size: 11px;
    font-weight: 300;
}