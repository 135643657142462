.home-different-equation__section-container {
  color: #fff;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  /* z-index: 1020; */
  /* background-size: 100%; */
  /* background-image: url("../../../shared/utils/images/bg.png"); */
}

.home-different-equation__bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
}

.home-different-equation__icon {
    margin: 10px;
    margin-left: 0;
}

.home-different-equation__col-1 {
    padding-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    /* background-image: url("../../../shared/utils/images/different-equation-bg-1.png");
    background-size: 101%;
    background-position: center; */
}

.home-different-equation__text-box {
    /* background-image: url("../../../shared/utils/images/different-equation-box-bg.png");
    background-position: center;
    background-size: 100%; */
    position: relative;
    width: 300px;
    min-height: 300px;
    padding: 30px;
    text-align: center;
    margin: 50px 20px;
}

.home-different-equation__text-box i {
    margin-top: 15px;
    font-size: 60px;
}

.home-different-equation__text-box>img {
    top: 0;
    right: 0;
}

.home-different-equation__quote-icon {
    width: 60;
    height: 60;
    margin: 8px;
}

.home-different-equation__col-2 {
    /* background-color: aqua; */
    /* padding: 75px 0; */
    padding: 0;
    /* background-image: url("../../../shared/utils/images/different-equation-bg-2.png");
    background-size: 101%;
    background-position: center; */
    position: relative;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    overflow: hidden;
}

.home-different-equation__col-2-container {
    padding: 100px 35px 75px 35px;
    max-width: 800px;
}

.home-different-equation__col-2-container h2 {
    font-size: 40px;
    max-width: 450px;
    margin-bottom: 40px;
    font-weight: 600;
}

.home-different-equation__col-2-container h3 {
    font-weight: bold;
}

.home-different-equation__col-2-container p,
.home-different-equation__text-box p {
    font-weight: 300;
}

.home-different-equation__col-2-container i {
    font-size: 30px;
    margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
    .home-different-equation__col-2-container {
        padding: 75px 35px;
    }   
}