.home-solutions__section-container {
    color: #fff;
    position: relative;
    overflow: hidden;
    /* background-size: 100%;
    background-image: url("../../../shared/utils/images/solutions-bg.jpeg"); */
}

.home-solutions__bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.home-solutions__wrapper {
    padding: 75px 0 120px 0;
}

.home-solutions__container h2 {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    font-weight: 600;
}

.home-solutions__row {
    position: relative;
    margin-top: 100px;
    /* background-image: url("../../../shared/utils/images/spectrum.png"); */
    /* background-size: contain; */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    /* padding: 100px 0; */
}

.home-solutions__row h4 {
    font-weight: 300;
    display: inline-block;
    font-size: 20px;
}

.home-solutions__row-bg-img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.home-solutions__inner-second-row {
    /* padding-top: calc(30vw - 100px); */
    /* padding-top: 7vw; */
    position: relative;
    top: 10vw;
}

.home-solutions__text-col-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    top: 25px;
    left: 30px;
}

.home-solutions__text-col-3 {
    position: relative;
    top: 25px;
}

.home-solutions__text-col-4 {
    position: relative;
    top: -5px;
}

.home-solutions__line {
    position: relative;
    display: flex;
    flex-direction: column;
    border: none;
    border-left: 2px dotted #fff;
    height: 15vh;
    max-height: 160px;
    min-height: 70px;
    width: 100%;
    justify-content: space-between;
}

.home-solutions__line-1 {
    left: 25px;
    top: 15px;
}

.home-solutions__line-link-point {
    width: 15px;
    height: 15px;
    background-color: #fff;
    background-clip: padding-box;
    border: solid 3px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    position: relative;
    top: -2px;
    left: -8px;
}

.home-solutions__line-text-point {
    width: 10px;
    height: 10px;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 100%;
    position: relative;
    left: -6px;
}

.home-solutions__icon {
    margin: 5px;
}

@media screen and (max-width: 576px) {
    .home-solutions__row h4 {
        font-size: 18px;
    }
}

@media screen and (min-width: 1200px) {
    .home-solutions__wrapper {
        padding-bottom: 250px;
    }
    
    .home-solutions__inner-second-row {
        top: 130px;
    }
}

@media screen and (max-width: 1399px) {
    .home-solutions__inner-second-row {
        top: 100px;
    }
}

@media screen and (max-width: 1199px) {
    .home-solutions__inner-second-row {
        top: 75px;
    }
}

@media screen and (max-width: 991px) {
    .home-solutions__text-col-2 {
        position: relative;
        top: 25px;
    }   
}

@media screen and (max-width: 767px) {
    .home-solutions__inner-second-row {
        top: 50px;
    }

    .home-solutions__text-col-1 {
        top: 20px;
        left: 10px;
    }
}

@media screen and (max-width: 575px) {
    .home-solutions__inner-second-row {
        top: 40px;
    }
}

@media screen and (max-width: 450px) {
    .home-solutions__inner-second-row {
        top: 30px;
    }
}