body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: #322b5b;
  /* background-color: red; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul {
  list-style-image: url(/static/media/list-icon.df2e8f8d.svg);
}

main > section {
  overflow: hidden;
}

::selection {
  background-color: #0170be;
}
.home-top__header-slider-content-container {
  /* overflow: hidden;
  width: 100vw;
  height: 100vh; */
}

/* .home-top__header-zoom-in {
  width: 100vw;
  height: 100vh;
  position: relative;
  -webkit-animation: zoom-in 5s ease-in;
  animation: zoom-in 5s ease-in;
  transition: all 0.5s ease-in;
  overflow: hidden;
} */

.home-top__content-wrapper {
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
}

.home-top__header-banner {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.home-top__content-container {
  color: #fff;
  position: absolute;
  top: 30vh;
  left: 8vw;
  z-index: 100;
}

.home-top__logo {
  max-width: 540px;
  width: 50vw;
  height: auto;
}

.home-top__text {
  margin-top: 25px;
  font-size: 20px;
  font-weight: 400;
}

/* @-webkit-keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
@keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
} */

@media screen and (min-width: 576px) {
  .home-top__content-wrapper {
    max-width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .home-top__content-wrapper {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .home-top__content-wrapper {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .home-top__content-wrapper {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1400px) {
  .home-top__content-wrapper {
    max-width: 1320px;
  }
}

.home-about__section-container {
  position: relative;
  color: #fff;
  min-height: 100vh;
  overflow: hidden;
  /* z-index: 1010; */
  /* background-size: 100%; */
  /* background-image: url("../../../shared/utils/images/about-bg.jpeg"); */
}

.home-about__container {
  /* padding: 80px 0; */
  padding-top: 100px;
  padding-bottom: 100px;
}

.home-about__bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

/* .home-about__row {
  overflow: hidden;
  margin-left: 100px;
} */

/* .home-about__text-col {
  margin-left: 100px;
} */

.home-about__heading {
  /* max-width: 500px; */
  margin-bottom: 80px;
  font-weight: bold;
  font-size: 34px;
}

.home-about__quote-icon {
    margin: 8px 0;
}

.home-about__text {
  font-weight: 300;
}

.home-about__earth-img {
  margin: 20px 0 10px 0;
  width: 100%;
  height: auto;
  position: relative;
  left: 60px;
}

@media screen and (max-width: 768px) {
  .home-about__earth-img {
    left: 0;
  }
}
.home-partners__section-container {
  position: relative;
  min-height: 280px;
  background-color: orange;
  /* z-index: 1030; */
}

.home-partners__container {
  padding-top: 40px;
}

.home-partners__heading,
.home-partners__sub-heading {
  text-align: center;
  color: #fff;
}

.home-partners__heading {
  font-weight: bold;
}

.home-partners__sub-heading {
  font-weight: 600;
}

.home-partners__carousel {
  margin-top: 40px;
}

.home-partners__carousel .swiper-wrapper {
  align-items: center;
}

.home-partners__slider-item {
  justify-content: center;
}

.home-partners__partner-logo {
  width: 80px;
  height: auto;
}

.home-partners__partner-logo-sm {
  width: 40px;
}

.home-different-equation__section-container {
  color: #fff;
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  /* z-index: 1020; */
  /* background-size: 100%; */
  /* background-image: url("../../../shared/utils/images/bg.png"); */
}

.home-different-equation__bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
}

.home-different-equation__icon {
    margin: 10px;
    margin-left: 0;
}

.home-different-equation__col-1 {
    padding-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    /* background-image: url("../../../shared/utils/images/different-equation-bg-1.png");
    background-size: 101%;
    background-position: center; */
}

.home-different-equation__text-box {
    /* background-image: url("../../../shared/utils/images/different-equation-box-bg.png");
    background-position: center;
    background-size: 100%; */
    position: relative;
    width: 300px;
    min-height: 300px;
    padding: 30px;
    text-align: center;
    margin: 50px 20px;
}

.home-different-equation__text-box i {
    margin-top: 15px;
    font-size: 60px;
}

.home-different-equation__text-box>img {
    top: 0;
    right: 0;
}

.home-different-equation__quote-icon {
    width: 60;
    height: 60;
    margin: 8px;
}

.home-different-equation__col-2 {
    /* background-color: aqua; */
    /* padding: 75px 0; */
    padding: 0;
    /* background-image: url("../../../shared/utils/images/different-equation-bg-2.png");
    background-size: 101%;
    background-position: center; */
    position: relative;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    overflow: hidden;
}

.home-different-equation__col-2-container {
    padding: 100px 35px 75px 35px;
    max-width: 800px;
}

.home-different-equation__col-2-container h2 {
    font-size: 40px;
    max-width: 450px;
    margin-bottom: 40px;
    font-weight: 600;
}

.home-different-equation__col-2-container h3 {
    font-weight: bold;
}

.home-different-equation__col-2-container p,
.home-different-equation__text-box p {
    font-weight: 300;
}

.home-different-equation__col-2-container i {
    font-size: 30px;
    margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
    .home-different-equation__col-2-container {
        padding: 75px 35px;
    }   
}
.home-solutions__section-container {
    color: #fff;
    position: relative;
    overflow: hidden;
    /* background-size: 100%;
    background-image: url("../../../shared/utils/images/solutions-bg.jpeg"); */
}

.home-solutions__bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.home-solutions__wrapper {
    padding: 75px 0 120px 0;
}

.home-solutions__container h2 {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    font-weight: 600;
}

.home-solutions__row {
    position: relative;
    margin-top: 100px;
    /* background-image: url("../../../shared/utils/images/spectrum.png"); */
    /* background-size: contain; */
    /* background-position: center; */
    /* background-repeat: no-repeat; */
    /* padding: 100px 0; */
}

.home-solutions__row h4 {
    font-weight: 300;
    display: inline-block;
    font-size: 20px;
}

.home-solutions__row-bg-img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
}

.home-solutions__inner-second-row {
    /* padding-top: calc(30vw - 100px); */
    /* padding-top: 7vw; */
    position: relative;
    top: 10vw;
}

.home-solutions__text-col-1 {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    top: 25px;
    left: 30px;
}

.home-solutions__text-col-3 {
    position: relative;
    top: 25px;
}

.home-solutions__text-col-4 {
    position: relative;
    top: -5px;
}

.home-solutions__line {
    position: relative;
    display: flex;
    flex-direction: column;
    border: none;
    border-left: 2px dotted #fff;
    height: 15vh;
    max-height: 160px;
    min-height: 70px;
    width: 100%;
    justify-content: space-between;
}

.home-solutions__line-1 {
    left: 25px;
    top: 15px;
}

.home-solutions__line-link-point {
    width: 15px;
    height: 15px;
    background-color: #fff;
    background-clip: padding-box;
    border: solid 3px rgba(255, 255, 255, 0.5);
    border-radius: 100%;
    position: relative;
    top: -2px;
    left: -8px;
}

.home-solutions__line-text-point {
    width: 10px;
    height: 10px;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 100%;
    position: relative;
    left: -6px;
}

.home-solutions__icon {
    margin: 5px;
}

@media screen and (max-width: 576px) {
    .home-solutions__row h4 {
        font-size: 18px;
    }
}

@media screen and (min-width: 1200px) {
    .home-solutions__wrapper {
        padding-bottom: 250px;
    }
    
    .home-solutions__inner-second-row {
        top: 130px;
    }
}

@media screen and (max-width: 1399px) {
    .home-solutions__inner-second-row {
        top: 100px;
    }
}

@media screen and (max-width: 1199px) {
    .home-solutions__inner-second-row {
        top: 75px;
    }
}

@media screen and (max-width: 991px) {
    .home-solutions__text-col-2 {
        position: relative;
        top: 25px;
    }   
}

@media screen and (max-width: 767px) {
    .home-solutions__inner-second-row {
        top: 50px;
    }

    .home-solutions__text-col-1 {
        top: 20px;
        left: 10px;
    }
}

@media screen and (max-width: 575px) {
    .home-solutions__inner-second-row {
        top: 40px;
    }
}

@media screen and (max-width: 450px) {
    .home-solutions__inner-second-row {
        top: 30px;
    }
}
.home-focus__section-container {
  position: relative;
  color: #fff;
  min-height: 80vh;
  /* background-size: 100%;
  background-image: url("../../../shared/utils/images/bg.png"); */
}

.home-focus__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.home-focus__wrapper {
  padding: 75px 30px 0 30px;
}

.home-focus__container h2 {
  font-weight: 600;
}

.home-focus__first-row-container {
  display: flex;
  justify-content: center;
}

.home-focus__second-row-container {
  display: flex;
  justify-content: center;
  -webkit-transform: translate(0, -28%);
          transform: translate(0, -28%);
}

.home-focus__content-row {
  flex-wrap: nowrap;
}

.home-focus__content-row-3 {
  flex-wrap: wrap;
}

.home-focus__square-col {
  display: flex;
  justify-content: center;
}

.home-focus__SR-square-col {
  padding: 45px 0;
}

.home-focus__part-container {
  max-width: 35vw;
  display: inline-block;
  padding: 45px 0;
  margin: 35px;
}

.home-focus__last-part-container {
  padding-top: 0;
  margin-top: 0;
}

.home-focus__diamond-square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13vw;
  max-width: 250px;
  min-width: 100px;
  height: 13vw;
  max-height: 250px;
  min-height: 100px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-size: 100%;
  background-position: center;
}

.home-focus__diamond-square-1 {
  background-image: url(/static/media/focus-img-1.64f38a0a.png);
}

.home-focus__diamond-square-2 {
  background-image: url(/static/media/focus-img-2.cd3bf434.png);
}

.home-focus__diamond-square-3 {
  background-image: url(/static/media/focus-img-3.6f6af967.png);
}

picture.home-focus__diamond-square-icon {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.home-focus__diamond-square-icon {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.home-focus__square-text-container {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.home-focus__square-text-container h3 {
  font-size: 20px;
  font-weight: bold;
}

.home-focus__square-text-container h4 {
  color: #00B0F0;
  font-size: 12px;
  font-weight: 600;
}

.home-focus__square-text-container p {
  font-size: 12px;
  font-weight: 300;
}

.home-focus__square-text-col {
  display: flex;
  align-items: center;
}

.home-focus__square-text-col-1 {
  text-align: right;
  margin-right: 20px;
}

.home-focus__square-text-col-2 {
  text-align: left;
  margin-left: 20px;
}

.home-focus__square-text-col-3 {
  text-align: center;
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  .home-focus__second-row-container {
    -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
  }
}

/* less than md */
@media screen and (max-width: 767px) {
  .home-focus__first-row-container {
    flex-direction: column;
  }

  .home-focus__part-container {
    max-width: unset;
  }

  .home-focus__part-container {
    margin: 0 auto;
  }

  .home-focus__content-row {
    width: 80vw;
  }

  .home-focus__content-row-1 {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .home-focus__square-text-col {
    text-align: left;
  }

  .home-focus__second-row-container {
    -webkit-transform: unset;
            transform: unset;
  }
}

.home-s-partnership__section-container {
  position: relative;
  color: #fff;
  min-height: 80vh;
  overflow: hidden;
  /* background-size: 100%;
  background-image: url("../../../shared/utils/images/successful-partnerships-bg.jpg"); */
}

.home-s-partnership__row {
  padding-left: 3px;
  padding-right: 3px;
}

.home-s-partnership__bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.home-s-partnership__container {
  padding-top: 100px;
  padding-bottom: 100px;
}

.home-s-partnership__image {
  width: 100%;
  height: auto;
  padding: 50px 10px;
}

.home-s-partnership__heading {
  max-width: 500px;
  margin-bottom: 30px;
  font-weight: 600;
}

.home-s-partnership__text {
  font-weight: 300;
}

.ui-service-card__col {
    margin-top: 30px;
    margin-bottom: 30px;
}

.ui-service-card__container {
    position: relative;
    display: flex;
    flex-direction: column;
    background-size: 100%;
    height: 100%;
    min-height: 450px;
    padding: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.ui-service-card__bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
}

.ui-service-card__icon-container {
    text-align: right;
    margin-bottom: 20px;
}

.ui-service-card__content-container h3 {
    font-size: 18px;
    font-weight: 500;
}

.ui-service-card__points-list {
    font-size: 11px;
    font-weight: 300;
}
.home-services__section-container {
  position: relative;
  color: #fff;
  min-height: 65vh;
  overflow: hidden;
  /* background-size: 100%;
  background-image: url("../../../shared/utils/images/bg.png"); */
}

.home-services__bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.home-services__wrapper {
    padding: 75px 30px;
}

/* .home-services__card-1 {
    background-image: url("../../../shared/utils/images/services-bg-1.png");
}

.home-services__card-2 {
    background-image: url("../../../shared/utils/images/services-bg-2.png");
}

.home-services__card-3 {
    background-image: url("../../../shared/utils/images/services-bg-3.png");
}

.home-services__card-4 {
    background-image: url("../../../shared/utils/images/services-bg-4.png");
} */

.home-services__container h2 {
    font-weight: 600;
    position: relative;
    z-index: 10;
}

.home-services__row {
    margin: 50px 25px;
}

.home-services__card {
    display: flex;
    flex-direction: column;
}

.home-services__card-image {
    background-color: red;
    width: 100%;
    height: 350px;
}

.home-services__card-title {
    margin: 20px 0;
    text-align: center;
}

.home-services__card-title h3 {
    font-size: 24px;
    font-weight: 500;
}


.ui-team-member__row {
    padding: 30px 10px;
    justify-content: center;
    align-items: center;
}

.ui-team-member__image-container {
    position: relative;
    width: 250px;
    height: 250px;
    /* background-color: red; */
    border-radius: 10px;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 45px;
    overflow: hidden;
}

.ui-team-member__image-container img {
    width: 100%;
    height: auto;
}

.ui-team-member__text-col h3 {
    color: #00F0F0;
}

.ui-team-member__text-col h3 {
    font-weight: 600;
}

.ui-team-member__text-col h4 {
    font-weight: 600;
}

.ui-team-member__description-container {
    font-weight: 300;
}

.home-team__section-container {
  position: relative;
  color: #fff;
  min-height: 100vh;
  /* background-size: 100%; */
  /* background-image: url("../../../shared/utils/images/solutions-bg.jpeg"); */
}

.home-team__wrapper {
  padding: 75px 30px;
}

.home-team__bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.home-team__container h2 {
  font-weight: 600;
}

#team {
    position: relative;
    z-index: 503;
}

/* #about {
    position: relative;
    z-index: 1020;
}

#partners {
    position: relative;
    z-index: 1030;
}

#different-equation {
    position: relative;
    z-index: 1020;
} */

/* .swiper,
.swiper-wrapper,
.swiper-slize {
    z-index: -1;
} */
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nav-links li {
  margin: 1rem;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 0.5rem;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  color: black;
}


@media (min-width: 768px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 0.5rem;
  }

  .nav-links a {
    color: white;
    text-decoration: none;
  }
}

#mainNav {
  background-color: transparent;
  transition: background-color 0.5s ease 0s;
}

#mainNav.navbar-shrink {
  background-color: #322b5b;
  transition: background-color 0.5s ease 0s;
}

#mainNav .navbar-brand {
  color: white;
  font-weight: bold;
}

.navbar .navbar-nav .nav-link {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  padding: 6px 20px;
}

.navbar-nav {
  margin-left: auto;
}

nav .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-toggler,
.navbar-toggler:active,
.navbar-toggler:focus,
.navbar-toggler:focus-visible {
  border: none;
  box-shadow: none;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}

.button-change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
  background-color: #fff;
}

.button-change .bar2 {
  opacity: 0;
}

.button-change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
  background-color: #fff;
}


@media screen and (max-width: 991px) {

  #mainNav .navbar-brand svg,
  #mainNav .navbar-brand img {
    height: 2.5rem;
    width: auto;
  }

  #responsive-navbar-nav {
    text-align: center;
  }
}

@media (min-width: 992px) {
  #mainNav {
    /* padding-top: 1.5rem; */
    /* padding-bottom: 1.5rem; */
    border: none;
    /* background-color: #fcb814; */
    /* background-color: darkblue; */
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }

  #mainNav .navbar-brand {
    font-size: 1.5em;
    transition: font-size 0.3s ease-in-out;
  }
  #mainNav .navbar-brand img {
    height: 4.3rem;
    width: auto;
    padding: 5px 0;
    transition: height 0.3s ease-in-out;
  }

  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.25em;
  }

  /* #mainNav.navbar-shrink .navbar-beta-tag {
    font-size: 6px;
    transition: font-size 0.3s ease-in-out;
  } */

  #mainNav.navbar-shrink .navbar-brand svg,
  #mainNav.navbar-shrink .navbar-brand img {
    height: 3.5rem;
    width: auto;
  }
}

/* Other Nav */

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}

.footer {
  background-color: #000;
  color: white !important;
  text-align: center;
  font-size: 0.9rem;
  padding: 50px;
}

.footer__col-1 {
  display: flex;
  flex-direction: column;
}

.footer__info {
  text-align: left;
  max-width: 350px;
  margin-bottom: 100px;
}

.footer__info h2 {
  text-decoration: underline;
  margin-bottom: 50px;
}

.footer__info-item {
  margin: 20px 0;
}

.footer__info-item a,
.footer__info-item a:hover,
.footer__info-item a:focus {
  color: #fff;
}

.footer__location-map {
  width: 100%;
  height: 350px;
}
.footer__copyright,
.footer__powered-by {
  text-align: center;
  margin: 5px 0;
}

.footer__powered-by-link,
.footer__powered-by-link:hover,
.footer__powered-by-link:focus {
  color: #fff;
  cursor: pointer;
}

.footer__links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 0;
}

.btn-social {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: #fff;
  color: #000;
  border-radius: 100%;
  margin: 5px;
}

.btn-social:hover {
  background-color: #322b5b;
  border-color: #322b5b;
}

.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: inherit;
  top: 0;
  left: 0;
  background: rgba(50, 43, 91, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.loading-spinner__icon {
  position: absolute;
  width: 10%;
  height: auto;
  -webkit-animation: spin 2.5s linear infinite;
  animation: spin 2.5s linear infinite;
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

