.home-team__section-container {
  position: relative;
  color: #fff;
  min-height: 100vh;
  /* background-size: 100%; */
  /* background-image: url("../../../shared/utils/images/solutions-bg.jpeg"); */
}

.home-team__wrapper {
  padding: 75px 30px;
}

.home-team__bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.home-team__container h2 {
  font-weight: 600;
}
