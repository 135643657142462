#team {
    position: relative;
    z-index: 503;
}

/* #about {
    position: relative;
    z-index: 1020;
}

#partners {
    position: relative;
    z-index: 1030;
}

#different-equation {
    position: relative;
    z-index: 1020;
} */

/* .swiper,
.swiper-wrapper,
.swiper-slize {
    z-index: -1;
} */