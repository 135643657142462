.home-top__header-slider-content-container {
  /* overflow: hidden;
  width: 100vw;
  height: 100vh; */
}

/* .home-top__header-zoom-in {
  width: 100vw;
  height: 100vh;
  position: relative;
  -webkit-animation: zoom-in 5s ease-in;
  animation: zoom-in 5s ease-in;
  transition: all 0.5s ease-in;
  overflow: hidden;
} */

.home-top__content-wrapper {
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: auto;
}

.home-top__header-banner {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.home-top__content-container {
  color: #fff;
  position: absolute;
  top: 30vh;
  left: 8vw;
  z-index: 100;
}

.home-top__logo {
  max-width: 540px;
  width: 50vw;
  height: auto;
}

.home-top__text {
  margin-top: 25px;
  font-size: 20px;
  font-weight: 400;
}

/* @-webkit-keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
@keyframes zoom-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
} */

@media screen and (min-width: 576px) {
  .home-top__content-wrapper {
    max-width: 540px;
  }
}

@media screen and (min-width: 768px) {
  .home-top__content-wrapper {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .home-top__content-wrapper {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .home-top__content-wrapper {
    max-width: 1140px;
  }
}

@media screen and (min-width: 1400px) {
  .home-top__content-wrapper {
    max-width: 1320px;
  }
}
