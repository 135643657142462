.home-about__section-container {
  position: relative;
  color: #fff;
  min-height: 100vh;
  overflow: hidden;
  /* z-index: 1010; */
  /* background-size: 100%; */
  /* background-image: url("../../../shared/utils/images/about-bg.jpeg"); */
}

.home-about__container {
  /* padding: 80px 0; */
  padding-top: 100px;
  padding-bottom: 100px;
}

.home-about__bg-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

/* .home-about__row {
  overflow: hidden;
  margin-left: 100px;
} */

/* .home-about__text-col {
  margin-left: 100px;
} */

.home-about__heading {
  /* max-width: 500px; */
  margin-bottom: 80px;
  font-weight: bold;
  font-size: 34px;
}

.home-about__quote-icon {
    margin: 8px 0;
}

.home-about__text {
  font-weight: 300;
}

.home-about__earth-img {
  margin: 20px 0 10px 0;
  width: 100%;
  height: auto;
  position: relative;
  left: 60px;
}

@media screen and (max-width: 768px) {
  .home-about__earth-img {
    left: 0;
  }
}