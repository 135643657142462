.home-s-partnership__section-container {
  position: relative;
  color: #fff;
  min-height: 80vh;
  overflow: hidden;
  /* background-size: 100%;
  background-image: url("../../../shared/utils/images/successful-partnerships-bg.jpg"); */
}

.home-s-partnership__row {
  padding-left: 3px;
  padding-right: 3px;
}

.home-s-partnership__bg-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.home-s-partnership__container {
  padding-top: 100px;
  padding-bottom: 100px;
}

.home-s-partnership__image {
  width: 100%;
  height: auto;
  padding: 50px 10px;
}

.home-s-partnership__heading {
  max-width: 500px;
  margin-bottom: 30px;
  font-weight: 600;
}

.home-s-partnership__text {
  font-weight: 300;
}
