.home-partners__section-container {
  position: relative;
  min-height: 280px;
  background-color: orange;
  /* z-index: 1030; */
}

.home-partners__container {
  padding-top: 40px;
}

.home-partners__heading,
.home-partners__sub-heading {
  text-align: center;
  color: #fff;
}

.home-partners__heading {
  font-weight: bold;
}

.home-partners__sub-heading {
  font-weight: 600;
}

.home-partners__carousel {
  margin-top: 40px;
}

.home-partners__carousel .swiper-wrapper {
  align-items: center;
}

.home-partners__slider-item {
  justify-content: center;
}

.home-partners__partner-logo {
  width: 80px;
  height: auto;
}

.home-partners__partner-logo-sm {
  width: 40px;
}
