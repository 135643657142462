.home-focus__section-container {
  position: relative;
  color: #fff;
  min-height: 80vh;
  /* background-size: 100%;
  background-image: url("../../../shared/utils/images/bg.png"); */
}

.home-focus__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
}

.home-focus__wrapper {
  padding: 75px 30px 0 30px;
}

.home-focus__container h2 {
  font-weight: 600;
}

.home-focus__first-row-container {
  display: flex;
  justify-content: center;
}

.home-focus__second-row-container {
  display: flex;
  justify-content: center;
  transform: translate(0, -28%);
}

.home-focus__content-row {
  flex-wrap: nowrap;
}

.home-focus__content-row-3 {
  flex-wrap: wrap;
}

.home-focus__square-col {
  display: flex;
  justify-content: center;
}

.home-focus__SR-square-col {
  padding: 45px 0;
}

.home-focus__part-container {
  max-width: 35vw;
  display: inline-block;
  padding: 45px 0;
  margin: 35px;
}

.home-focus__last-part-container {
  padding-top: 0;
  margin-top: 0;
}

.home-focus__diamond-square {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13vw;
  max-width: 250px;
  min-width: 100px;
  height: 13vw;
  max-height: 250px;
  min-height: 100px;
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  background-size: 100%;
  background-position: center;
}

.home-focus__diamond-square-1 {
  background-image: url("../../../shared/utils/images/focus-img-1.png");
}

.home-focus__diamond-square-2 {
  background-image: url("../../../shared/utils/images/focus-img-2.png");
}

.home-focus__diamond-square-3 {
  background-image: url("../../../shared/utils/images/focus-img-3.png");
}

picture.home-focus__diamond-square-icon {
  width: 80%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

img.home-focus__diamond-square-icon {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  transform: rotate(45deg);
}

.home-focus__square-text-container {
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
}

.home-focus__square-text-container h3 {
  font-size: 20px;
  font-weight: bold;
}

.home-focus__square-text-container h4 {
  color: #00B0F0;
  font-size: 12px;
  font-weight: 600;
}

.home-focus__square-text-container p {
  font-size: 12px;
  font-weight: 300;
}

.home-focus__square-text-col {
  display: flex;
  align-items: center;
}

.home-focus__square-text-col-1 {
  text-align: right;
  margin-right: 20px;
}

.home-focus__square-text-col-2 {
  text-align: left;
  margin-left: 20px;
}

.home-focus__square-text-col-3 {
  text-align: center;
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  .home-focus__second-row-container {
    transform: translate(0, -50%);
  }
}

/* less than md */
@media screen and (max-width: 767px) {
  .home-focus__first-row-container {
    flex-direction: column;
  }

  .home-focus__part-container {
    max-width: unset;
  }

  .home-focus__part-container {
    margin: 0 auto;
  }

  .home-focus__content-row {
    width: 80vw;
  }

  .home-focus__content-row-1 {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }

  .home-focus__square-text-col {
    text-align: left;
  }

  .home-focus__second-row-container {
    transform: unset;
  }
}
