.loading-spinner__overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: inherit;
  top: 0;
  left: 0;
  background: rgba(50, 43, 91, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
}

.loading-spinner__icon {
  position: absolute;
  width: 10%;
  height: auto;
  -webkit-animation: spin 2.5s linear infinite;
  -moz-animation: spin 2.5s linear infinite;
  animation: spin 2.5s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
