.home-services__section-container {
  position: relative;
  color: #fff;
  min-height: 65vh;
  overflow: hidden;
  /* background-size: 100%;
  background-image: url("../../../shared/utils/images/bg.png"); */
}

.home-services__bg-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
}

.home-services__wrapper {
    padding: 75px 30px;
}

/* .home-services__card-1 {
    background-image: url("../../../shared/utils/images/services-bg-1.png");
}

.home-services__card-2 {
    background-image: url("../../../shared/utils/images/services-bg-2.png");
}

.home-services__card-3 {
    background-image: url("../../../shared/utils/images/services-bg-3.png");
}

.home-services__card-4 {
    background-image: url("../../../shared/utils/images/services-bg-4.png");
} */

.home-services__container h2 {
    font-weight: 600;
    position: relative;
    z-index: 10;
}

.home-services__row {
    margin: 50px 25px;
}

.home-services__card {
    display: flex;
    flex-direction: column;
}

.home-services__card-image {
    background-color: red;
    width: 100%;
    height: 350px;
}

.home-services__card-title {
    margin: 20px 0;
    text-align: center;
}

.home-services__card-title h3 {
    font-size: 24px;
    font-weight: 500;
}

