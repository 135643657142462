.ui-team-member__row {
    padding: 30px 10px;
    justify-content: center;
    align-items: center;
}

.ui-team-member__image-container {
    position: relative;
    width: 250px;
    height: 250px;
    /* background-color: red; */
    border-radius: 10px;
    margin: 20px auto;
    background-color: #fff;
    border-radius: 45px;
    overflow: hidden;
}

.ui-team-member__image-container img {
    width: 100%;
    height: auto;
}

.ui-team-member__text-col h3 {
    color: #00F0F0;
}

.ui-team-member__text-col h3 {
    font-weight: 600;
}

.ui-team-member__text-col h4 {
    font-weight: 600;
}

.ui-team-member__description-container {
    font-weight: 300;
}
