#mainNav {
  background-color: transparent;
  transition: background-color 0.5s ease 0s;
}

#mainNav.navbar-shrink {
  background-color: #322b5b;
  transition: background-color 0.5s ease 0s;
}

#mainNav .navbar-brand {
  color: white;
  font-weight: bold;
}

.navbar .navbar-nav .nav-link {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  padding: 6px 20px;
}

.navbar-nav {
  margin-left: auto;
}

nav .navbar-nav .nav-link.active,
.navbar .navbar-nav .nav-link:hover {
  color: #fff;
}

.navbar-toggler,
.navbar-toggler:active,
.navbar-toggler:focus,
.navbar-toggler:focus-visible {
  border: none;
  box-shadow: none;
}

.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 5px;
  background-color: #fff;
  margin: 6px 0;

  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -o-transition: 0.4s;
  -ms-transition: 0.4s;
  transition: 0.4s;
}

.button-change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  -moz-transform: rotate(-45deg) translate(-9px, 6px);
  -o-transform: rotate(-45deg) translate(-9px, 6px);
  -ms-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
  background-color: #fff;
}

.button-change .bar2 {
  opacity: 0;
}

.button-change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  -moz-transform: rotate(45deg) translate(-8px, -8px);
  -o-transform: rotate(45deg) translate(-8px, -8px);
  -ms-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
  background-color: #fff;
}


@media screen and (max-width: 991px) {

  #mainNav .navbar-brand svg,
  #mainNav .navbar-brand img {
    height: 2.5rem;
    width: auto;
  }

  #responsive-navbar-nav {
    text-align: center;
  }
}

@media (min-width: 992px) {
  #mainNav {
    /* padding-top: 1.5rem; */
    /* padding-bottom: 1.5rem; */
    border: none;
    /* background-color: #fcb814; */
    /* background-color: darkblue; */
    transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }

  #mainNav .navbar-brand {
    font-size: 1.5em;
    transition: font-size 0.3s ease-in-out;
  }
  #mainNav .navbar-brand img {
    height: 4.3rem;
    width: auto;
    padding: 5px 0;
    transition: height 0.3s ease-in-out;
  }

  #mainNav.navbar-shrink .navbar-brand {
    font-size: 1.25em;
  }

  /* #mainNav.navbar-shrink .navbar-beta-tag {
    font-size: 6px;
    transition: font-size 0.3s ease-in-out;
  } */

  #mainNav.navbar-shrink .navbar-brand svg,
  #mainNav.navbar-shrink .navbar-brand img {
    height: 3.5rem;
    width: auto;
  }
}

/* Other Nav */

.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 2rem;
  cursor: pointer;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: white;
}

.main-navigation__title {
  color: white;
}

.main-navigation__title a {
  text-decoration: none;
  color: white;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
}

@media (min-width: 768px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
}
