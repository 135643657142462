.footer {
  background-color: #000;
  color: white !important;
  text-align: center;
  font-size: 0.9rem;
  padding: 50px;
}

.footer__col-1 {
  display: flex;
  flex-direction: column;
}

.footer__info {
  text-align: left;
  max-width: 350px;
  margin-bottom: 100px;
}

.footer__info h2 {
  text-decoration: underline;
  margin-bottom: 50px;
}

.footer__info-item {
  margin: 20px 0;
}

.footer__info-item a,
.footer__info-item a:hover,
.footer__info-item a:focus {
  color: #fff;
}

.footer__location-map {
  width: 100%;
  height: 350px;
}
.footer__copyright,
.footer__powered-by {
  text-align: center;
  margin: 5px 0;
}

.footer__powered-by-link,
.footer__powered-by-link:hover,
.footer__powered-by-link:focus {
  color: #fff;
  cursor: pointer;
}

.footer__links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 5px 0;
}

.btn-social {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  background-color: #fff;
  color: #000;
  border-radius: 100%;
  margin: 5px;
}

.btn-social:hover {
  background-color: #322b5b;
  border-color: #322b5b;
}
